import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const data = [
  { name: 'Sep', kpi: 2 },
  { name: 'Oct', kpi: 3 },
  { name: 'Nov', kpi: 4 },
  { name: 'Dec', kpi: 9 },
  { name: 'Jan (2025)', kpi: 10 },
  { name: 'Feb', kpi: 15 },
  { name: 'Mar', kpi: 19 },
];

export default function KPIChart() {
  // Extract the last KPI value dynamically
  const currentKPI = data[data.length - 1].kpi;
  const goalKPI = 50; // or any other way you define the goal

  return (
    <div className='flex flex-col gap-2'>
      {/* Dynamically display current KPI here */}
      <div className='text-9xl pl-6'>
        {currentKPI}/{goalKPI}
      </div>
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 20, right: 60, left: 0, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, 60]} />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="kpi"
              stroke="#424cb3"
              strokeWidth={2}
            />
            {/* Reference line at y=goalKPI (our goal) */}
            <ReferenceLine
              y={goalKPI}
              stroke="green"
              strokeDasharray="3 3"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
