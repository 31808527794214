"use client";

import { LucideIcon } from "lucide-react";
import { IconType } from "react-icons";

import Link from "next/link";

import { buttonVariants } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";

interface NavProps {
   isCollapsed: boolean;
   links: {
      title: string;
      label?: React.ReactNode;
      icon: React.ReactNode;
      href: string;
      onClick?: () => void;
   }[];
}

export function Nav({ links, isCollapsed }: NavProps) {
   const pathname = usePathname();

   return (
      <div data-collapsed={isCollapsed} className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2">
         <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
            {links.map((link, index) =>
               isCollapsed ? (
                  <Tooltip key={index} delayDuration={0}>
                     <TooltipTrigger asChild>
                        <Link
                           href={link.href}
                           className={cn(
                              buttonVariants({ variant: link.href === pathname ? "default" : "ghost", size: "icon" }),
                              "h-9 w-9 rounded",
                              link.href === pathname &&
                                 "bg-white text-black hover:bg-white hover:text-black"
                           )}
                           onClick={() => {
                              if (link.onClick) {
                                 link.onClick();
                              }
                           }}
                        >
                           {link.icon}
                           <span className="sr-only">{link.title}</span>
                        </Link>
                     </TooltipTrigger>
                     <TooltipContent side="right" className="flex items-center gap-4">
                        {link.title}
                        {link.label && <span className="ml-auto text-muted-foreground">{link.label}</span>}
                     </TooltipContent>
                  </Tooltip>
               ) : (
                  <Link
                     key={index}
                     href={link.href}
                     className={cn(
                        buttonVariants({ variant: link.href === pathname ? "default" : "ghost", size: "sm" }),
                        link.href === pathname &&
                           "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                        "justify-start"
                     )}
                  >
                      {link.icon}
                     {link.title}
                     {link.label && (
                        <span className={cn("ml-auto", link.href === pathname && "text-background dark:text-white")}>
                           {link.label}
                        </span>
                     )}
                  </Link>
               )
            )}
         </nav>
      </div>
   );
}
