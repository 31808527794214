import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  canGetDemoBannerWithEmailRequest,
  canUseQuestion_LocalStorageRateLimit,
} from "@shared/acm/utils";
import { editUser } from "@shared/db/usersandhistory/src/utils_users_groups";
import {
  RequestAccessData
} from "@shared/dto/feedback";
import { Globe } from "lucide-react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { languageSettingGlobal } from "./state";

const ATTEMPT_RESET_TIME_IN_HOURS = {
  2: 0.083333366667, // 5 min
  4: 0.083333366667, // 5 min
  6: 0.5, // 30 min
  8: 1, // 1 hour
  10: 5, // 5 hours
  12: 72, // 72 hours
  14: 1000, // 1000 hours
};

const dictionary = {
  thanksForInterest: {
    en: "Thank you for your interest in Omnilex",
    it: "Grazie per il tuo interesse in Omnilex",
    fr: "Merci pour votre intérêt pour Omnilex",
    de: "Danke für dein Interesse an Omnilex",
    pt: "Obrigado pelo seu interesse no Omnilex",
  },
  timeUntilNextAttempt: {
    en: "Time until next attempt:",
    it: "Tempo fino al prossimo tentativo:",
    fr: "Temps jusqu'à la prochaine tentative:",
    de: "Zeit bis zum nächsten Versuch:",
    pt: "Tempo até a próxima tentativa:",
  },
  reachedFreeLimit: {
    en: "You have reached the number of free requests.",
    it: "Hai raggiunto il numero di richieste gratuite.",
    fr: "Vous avez atteint le nombre de demandes gratuites.",
    de: "Du hast die Anzahl frei verfügbarer Anfragen erreicht.",
    pt: "Você atingiu o número de solicitações gratuitas.",
  },
  reachedFreeLimitNeedEmail: {
    en: "You have reached the number of free requests. Enter your email to continue accessing Omnilex.",
    it: "Hai raggiunto il numero di richieste gratuite. Inserisci il tuo indirizzo email per continuare ad accedere a Omnilex.",
    fr: "Vous avez atteint le nombre de demandes gratuites. Saisissez votre adresse e-mail pour continuer à accéder à Omnilex.",
    de: "Du hast die Anzahl frei verfügbarer Anfragen erreicht. Gib deine E-Mail Adresse an, um weiterhin Zugriff auf Omnilex zu haben.",
    pt: "Você atingiu o número de solicitações gratuitas. Insira seu e-mail para continuar acessando o Omnilex.",
  },
  emailLabel: {
    en: "Email",
    it: "Email",
    fr: "Email",
    de: "Email",
    pt: "Email",
  },
  messageLabel: {
    en: "Message",
    it: "Messaggio",
    fr: "Message",
    de: "Nachricht",
    pt: "Mensagem",
  },
  sendRequest: {
    en: "Send Request",
    it: "Invia richiesta",
    fr: "Envoyer la demande",
    de: "Anfrage senden",
    pt: "Enviar solicitação",
  },
  messageSentSuccess: {
    en: "Message successfully sent",
    it: "Messaggio inviato con successo",
    fr: "Message envoyé avec succès",
    de: "Nachricht erfolgreich gesendet",
    pt: "Mensagem enviada com sucesso",
  },
  alreadyHaveLogin: {
    en: "Already have a login?",
    it: "Hai già un account?",
    fr: "Vous avez déjà un compte?",
    de: "Du hast bereits ein Login?",
    pt: "Já tem login?",
  },
  loginButton: {
    en: "Login",
    it: "Accedi",
    fr: "Connexion",
    de: "Login",
    pt: "Login",
  },
  usingDemoAccess: {
    en: "You are currently using the demo access.",
    it: "Stai utilizzando l'accesso demo.",
    fr: "Vous utilisez actuellement l'accès démo.",
    de: "Du nutzt derzeit den Demo-Zugang.",
    pt: "Você está usando o acesso de demonstração.",
  },
  enterEmailToContinue: {
    en: "Enter your email to continue accessing Omnilex without the demo limitations. Feel free to send us a message.",
    it: "Inserisci la tua email per continuare ad accedere a Omnilex senza limitazioni demo. Sentiti libero di inviarci un messaggio.",
    fr: "Entrez votre adresse e-mail pour continuer à accéder à Omnilex sans les limitations de la démo. N'hésitez pas à nous envoyer un message.",
    de: "Geben Sie Ihre E-Mail-Adresse ein, um weiterhin Zugriff auf Omnilex ohne Demoeinschränkungen zu haben. Sie können uns gerne eine Nachricht senden.",
    pt: "Digite seu e-mail para continuar acessando o Omnilex sem as limitações da demonstração. Sinta-se à vontade para nos enviar uma mensagem.",
  },
  sendButton: {
    en: "Send",
    it: "Invia",
    fr: "Envoyer",
    de: "Senden",
    pt: "Enviar",
  },
};

export function getLocalStorageItem(key: string, defaultValue: any) {
  if (typeof window !== "undefined") {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } else {
    return defaultValue;
  }
}

export function setLocalStorageItem(key: string, value: any) {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function calculateTimeDifferenceInHours(
  date1: Date,
  date2: Date
): number {
  return (date2.getTime() - date1.getTime()) / (1000 * 60 * 60);
}

export function getTimeToWaitInHours(attemptsTot: number): number {
  for (const [limit, time] of Object.entries(ATTEMPT_RESET_TIME_IN_HOURS)) {
    if (attemptsTot <= parseInt(limit)) {
      return time;
    }
  }
  return 0;
}

export function incrementLocalStorageItem(key: string): void {
  const value = getLocalStorageItem(key, 0);
  setLocalStorageItem(key, value + 1);
}

export default function DemoBlock({
  closeBlock,
  session,
}: {
  closeBlock: () => void;
  session: any;
}) {
  const [trailModeEmail, setTrailModeEmail] = useState("");
  const [trailModeMessage, setTrailModeMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const { toast } = useToast();
  const [lang, setLang] = useRecoilState(languageSettingGlobal);

  useEffect(() => {
    const attemptsTot = getLocalStorageItem("attempts_tot", 0);
    const lastDate = new Date(getLocalStorageItem("last_date", Date.now()));
    const currentDate = new Date();
    const timeToWaitInHour = getTimeToWaitInHours(attemptsTot);
    const timeDifferenceInHours = calculateTimeDifferenceInHours(
      lastDate,
      currentDate
    );
    const remainingTime = timeToWaitInHour - timeDifferenceInHours;
    if (remainingTime > 0) {
      setTimeLeft(remainingTime * 3600); // convert to seconds
    } else {
      setTimeLeft(null);
    }
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft && prevTimeLeft > 1) {
          return prevTimeLeft - 1;
        } else {
          clearInterval(interval);
          return null;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTimeLeft = (seconds: number) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(Math.floor(seconds % 60)).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  const email = session?.data?.user?.email;

  const reachedFreeLimit = false;

  const [code, setCode] = useState("");

  const [requestEmail, setRequestEmail] = useState(false);
  const [useLocalRateLimit, setUseLocalRateLimit] = useState(false);
  useEffect(() => {
    const email = session.data?.user?.email;
    if (!email) return;
    if (email === "") return;
    canGetDemoBannerWithEmailRequest(email).then((canUse) => {
      setRequestEmail(canUse);
    });
    canUseQuestion_LocalStorageRateLimit(email).then((canUse) => {
      setUseLocalRateLimit(canUse);
    });
  }, [session.data?.user?.email]);

  return (
    <div>
      <div className="absolute h-screen w-screen grid place-items-center backdrop-blur-sm top-0">
        <div className="border rounded bg-white p-4 flex flex-col gap-2 relative max-w-sm m-2">
          <div className="absolute top-0 right-0">
            <Button
              variant="ghost"
              onClick={() => {
                closeBlock();
              }}
              className="h-8 px-2 lg:px-3 m-2"
            >
              <Cross2Icon className="h-4 w-4" />
            </Button>
          </div>

          <p className=" font-medium pt-10">
            {dictionary.thanksForInterest[lang]}
          </p>

          <div>{dictionary.usingDemoAccess[lang]}</div>

          {reachedFreeLimit ? (
            <>
              {useLocalRateLimit ? (
                <>
                  {timeLeft !== null && (
                    <p className=" font-medium text-omnilex_neutral-600">
                      {dictionary.timeUntilNextAttempt[lang]}{" "}
                      {formatTimeLeft(timeLeft)}
                    </p>
                  )}
                  <p>{dictionary.reachedFreeLimitNeedEmail[lang]}</p>

                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="Email">{dictionary.emailLabel[lang]}</Label>
                    <Input
                      id="Email"
                      value={trailModeEmail}
                      onChange={(e) => setTrailModeEmail(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>{dictionary.reachedFreeLimit[lang]}</p>
                </>
              )}
            </>
          ) : (
            <>
              {requestEmail ? (
                <>
                  <div>{dictionary.enterEmailToContinue[lang]}</div>
                  <div className="grid w-full items-center gap-1.5">
                    <Label htmlFor="Email">{dictionary.emailLabel[lang]}</Label>
                    <Input
                      id="Email"
                      value={trailModeEmail}
                      onChange={(e) => setTrailModeEmail(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <div className="grid w-full items-center gap-1.5">
            <Label>{dictionary.messageLabel[lang]}</Label>
            <Textarea
              value={trailModeMessage}
              onChange={(e) => setTrailModeMessage(e.target.value)}
              className="border border-omnilex_neutral-600 focus-within:border-omnilex_neutral-900 rounded"
            />
          </div>

          <Button
            className="rounded"
            onClick={() => {
              const requestAccessData: RequestAccessData = {
                message: trailModeMessage,
                email: trailModeEmail,
                appEmail: email ?? "",
                dateTime: new Date().toISOString(),
              };
              fetch(
                process.env.NEXT_PUBLIC_BACKEND_URL + "/contact/request-access",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${session?.data?.accessToken}`,
                  },
                  body: JSON.stringify(requestAccessData),
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  console.log("Submission successful", data);
                  closeBlock();
                  toast({
                    description: dictionary.messageSentSuccess[lang],
                  });
                })
                .catch((error: any) => {
                  console.error("Submission failed", error);
                  closeBlock();
                });
            }}
          >
            {dictionary.sendRequest[lang]}
          </Button>

          {requestEmail ? (
            <>
              <Separator />
              <div className="flex flex-row items-center justify-between">
                <p>{dictionary.alreadyHaveLogin[lang]}</p>
                <a href="/login">
                  <Button variant="secondary"
                  className="rounded"
                  >
                    {dictionary.loginButton[lang]}
                  </Button>
                </a>
              </div>
            </>
          ) : (
            <></>
          )}

          {requestEmail ? (
            <></>
          ) : (
            <>
              <Separator />
              <div className="flex flex-row items-center gap-2 justify-between">
                <Input
                  placeholder="Use Omnilex Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="rounded"
                />
                <Button
                  variant="secondary"
                  className="rounded"
                  onClick={() => {
                    const code_clean = code.trim();
                    const groupCodes: { [key: string]: string } = {
                      "0f49964": "UniBern - SLTA",
                      "2313613": "UniBern - Students",
                      "1363690": "UniBern - ELSA",
                      e1ce241: "ZHAW - StVML",
                      "67fd60f": "ZHAW - Students",
                      "1642231": "UZH - SLTA",
                      bfe4546: "UZH - Students",
                      "1fws583": "UniBern - Faculty of Law",
                      OMNILEXatWEBLAW: "Weblaw Courses",
                      OMNILEX2RDK: "Recht-Digital Kurs",
                    };

                    const group = groupCodes[code_clean];

                    if (group) {
                      editUser(email, group, undefined).then(() => {
                        toast({
                          description: "Code accepted",
                        });
                        closeBlock();
                      });
                    } else {
                      toast({
                        description: "Invalid code",
                      });
                    }
                  }}
                >
                  {dictionary.sendButton[lang]}
                </Button>
              </div>
            </>
          )}

          <Separator />
          <div className="flex flex-row gap-2 items-center">
            <Globe className="h-4 w-4" />
            <a
              href="https://omnilex.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              omnilex.ai
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
