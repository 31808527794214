import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";

import { ScrollArea } from "@/components/ui/scroll-area";
import { Send } from "lucide-react";
import { useRecoilState } from "recoil";
import { handleClickSubmit } from "./registrationBannerServer";
import { langType, userSettingGlobal } from "./state";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const dictionary = {
  letsetupOmnilex: {
    en: "Let's set up Omnilex",
    de: "Lass uns Omnilex einrichten",
    fr: "Configurons Omnilex",
    it: "Configuriamo Omnilex",
    pt: "Vamos configurar o Omnilex",
  },

  // Labels
  emailLabel: {
    en: "Email",
    fr: "E-mail",
    de: "E-Mail",
    it: "Email",
    pt: "E-mail",
  },
  firstNameLabel: {
    en: "First Name",
    fr: "Prénom",
    de: "Vorname",
    it: "Nome",
    pt: "Nome",
  },
  lastNameLabel: {
    en: "Last Name",
    fr: "Nom",
    de: "Nachname",
    it: "Cognome",
    pt: "Sobrenome",
  },
  organizationTypeLabel: {
    en: "Organization Type",
    fr: "Type d'organisation",
    de: "Organisationstyp",
    it: "Tipo di organizzazione",
    pt: "Tipo de organização",
  },
  organizationNameLabel: {
    en: "Organization Name",
    fr: "Nom de l'organisation",
    de: "Name der Organisation",
    it: "Nome dell'organizzazione",
    pt: "Nome da organização",
  },
  areYouStudentLabel: {
    en: (
      <>
        <span>Are you a student?</span>{" "}
        <span className="text-omnilex_neutral-600 font-normal">
          Activates student mode for exams and lectures
        </span>
      </>
    ),
    fr: (
      <>
        <span>Êtes-vous étudiant(e) ?</span>{" "}
        <span className="text-omnilex_neutral-600 font-normal">
          Active le mode étudiant pour les examens et les cours
        </span>
      </>
    ),
    de: (
      <>
        <span>Bist du Student/in?</span>{" "}
        <span className="text-omnilex_neutral-600 font-normal">
          Aktiviert den Studentenmodus für Prüfungen und Vorlesungen
        </span>
      </>
    ),
    it: (
      <>
        <span>Sei uno studente?</span>{" "}
        <span className="text-omnilex_neutral-600 font-normal">
          Attiva la modalità studentesca per esami e lezioni
        </span>
      </>
    ),
    pt: (
      <>
        <span>És estudante?</span>{" "}
        <span className="text-omnilex_neutral-600 font-normal">
          Ativa o modo estudante para exames e aulas
        </span>
      </>
    ),
  },
  jurisdictionLabel: {
    en: "Jurisdiction of Interest",
    fr: "Juridiction d'intérêt",
    de: "Gewünschtes Rechtssystem",
    it: "Giurisdizione di interesse",
    pt: "Jurisdição de interesse",
  },
  howDidYouFindUsLabel: {
    de: "Wie hast du über Omnilex erfahren?",
    en: "How did you find out about Omnilex?",
    fr: "Comment avez-vous découvert Omnilex ?",
    it: "Come hai scoperto Omnilex?",
    pt: "Como descobriste a Omnilex?",
  },

  // Select placeholders and options
  selectOnePlaceholder: {
    en: "Select one...",
    fr: "Sélectionnez-en un...",
    de: "Bitte auswählen...",
    it: "Seleziona uno...",
    pt: "Selecione um...",
  },
  yesOption: {
    en: "Yes",
    fr: "Oui",
    de: "Ja",
    it: "Sì",
    pt: "Sim",
  },
  noOption: {
    en: "No",
    fr: "Non",
    de: "Nein",
    it: "No",
    pt: "Não",
  },
  lawFirmOption: {
    en: "Law Firm",
    fr: "Cabinet d'avocats",
    de: "Anwaltskanzlei",
    it: "Studio legale",
    pt: "Escritório de advocacia",
  },
  insuranceOption: {
    en: "Insurance",
    fr: "Assurance",
    de: "Versicherung",
    it: "Assicurazione",
    pt: "Seguro",
  },
  legalTeamOption: {
    en: "Legal Team",
    fr: "Équipe juridique",
    de: "Rechtsteam",
    it: "Team legale",
    pt: "Equipe jurídica",
  },
  universityOption: {
    en: "University",
    fr: "Université",
    de: "Universität",
    it: "Università",
    pt: "Universidade",
  },
  institutionGovOption: {
    en: "Institution/Government",
    fr: "Institution/Gouvernement",
    de: "Institution/Regierung",
    it: "Istituzione/Governo",
    pt: "Instituição/Governo",
  },
  associationNonprofitOption: {
    en: "Association/Nonprofit",
    fr: "Association/ONG",
    de: "Verein/Gemeinnützige",
    it: "Associazione/Non profit",
    pt: "Associação/Organização sem fins lucrativos",
  },
  friendsOption: {
    en: "Friends",
    fr: "Amis",
    de: "Freunde",
    it: "Amici",
    pt: "Amigos",
  },
  linkedinOption: {
    en: "Linkedin",
    fr: "Linkedin",
    de: "Linkedin",
    it: "Linkedin",
    pt: "Linkedin",
  },
  weblawOption: {
    en: "Weblaw",
    fr: "Weblaw",
    de: "Weblaw",
    it: "Weblaw",
    pt: "Weblaw",
  },
  socialMediaOption: {
    en: "Social media",
    fr: "Réseaux sociaux",
    de: "Soziale Medien",
    it: "Social media",
    pt: "Redes sociais",
  },

  // Button
  submitButton: {
    de: "Zu Omnilex",
    fr: "Aller à Omnilex",
    en: "Go to Omnilex",
    it: "Vai a Omnilex",
    pt: "Ir para a Omnilex",
  },

  // Error messages
  errorFirstNameRequired: {
    en: "First Name is required.",
    fr: "Le prénom est requis.",
    de: "Der Vorname ist erforderlich.",
    it: "Il nome è obbligatorio.",
    pt: "O nome é obrigatório.",
  },
  errorLastNameRequired: {
    en: "Last Name is required.",
    fr: "Le nom est requis.",
    de: "Der Nachname ist erforderlich.",
    it: "Il cognome è obbligatorio.",
    pt: "O sobrenome é obrigatório.",
  },
  errorOrgTypeRequired: {
    en: "Organization Type is required.",
    fr: "Le type d'organisation est requis.",
    de: "Der Organisationstyp ist erforderlich.",
    it: "Il tipo di organizzazione è obbligatorio.",
    pt: "O tipo de organização é obrigatório.",
  },
  errorOrgNameRequired: {
    en: "Organization Name is required.",
    fr: "Le nom de l'organisation est requis.",
    de: "Der Organisationsname ist erforderlich.",
    it: "Il nome dell'organizzazione è obbligatorio.",
    pt: "O nome da organização é obrigatório.",
  },
  errorStudentRequired: {
    en: "Please indicate if you are a student.",
    fr: "Veuillez indiquer si vous êtes un étudiant.",
    de: "Bitte geben Sie an, ob Sie ein Student sind.",
    it: "Per favore indica se sei uno studente.",
    pt: "Por favor, indique se é estudante.",
  },
  errorCountryRequired: {
    en: "Country is required.",
    fr: "Le pays est requis.",
    de: "Das Land ist erforderlich.",
    it: "Il paese è obbligatorio.",
    pt: "O país é obrigatório.",
  },
  errorComeFromRequired: {
    en: "Please indicate how you found us.",
    fr: "Veuillez indiquer comment vous nous avez trouvés.",
    de: "Bitte geben Sie an, wie Sie uns gefunden haben.",
    it: "Per favore indica come ci hai trovati.",
    pt: "Por favor, indique como nos encontrou.",
  },
  switzerlandOption: {
    en: "Switzerland",
    fr: "Suisse",
    de: "Schweiz",
    it: "Svizzera",
    pt: "Suíça",
  },
  germanyOption: {
    en: "Germany",
    fr: "Allemagne",
    de: "Deutschland",
    it: "Germania",
    pt: "Alemanha",
  },
  franceOption: {
    en: "France",
    fr: "France",
    de: "Frankreich",
    it: "Francia",
    pt: "França",
  },
  brazilOption: {
    en: "Brazil",
    fr: "Brésil",
    de: "Brasilien",
    it: "Brasile",
    pt: "Brasil",
  },
  usaOption: {
    en: "USA",
    fr: "États-Unis",
    de: "USA",
    it: "USA",
    pt: "EUA",
  },
};

import { useSearchParams } from "next/navigation";

function GetStartedOverlay({
  email,
  lang,
  onComplete,
}: {
  email: string | null | undefined;
  lang: langType;
  onComplete: () => void;
}) {
  // Local state for other fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orgType, setOrgType] = useState("");
  const [orgName, setOrgName] = useState("");
  const [student, setStudent] = useState<boolean | null>(null);
  const [comeFrom, setComeFrom] = useState<string>("");

  const [country, setCountry] = useState<string>("");

  const [userSettings, setUserSettings] = useRecoilState(userSettingGlobal);

  const searchParams = useSearchParams();
  const sourceParam = searchParams.get("source") ?? "";

  useEffect(() => {
    if (sourceParam !== "") {
      setComeFrom(sourceParam);
    }
  }, [sourceParam]);

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch("/api/ip");
        const data = await response.json();
        if (data.countryCode) {
          if (["CH", "DE", "FR", "BR", "US"].includes(data.countryCode)) {
            setCountry(data.countryCode);
          } else {
            setCountry("CH");
          }
        }
      } catch (error) {
        console.error("Error detecting country:", error);
        setCountry("CH");
      }
    };
    detectCountry();
  }, []);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const email_f = email || "";

  if (!email) {
    return <></>;
  }
  if (email.trim() === "") {
    return <></>;
  }

  const validateForm = () => {
    const errors: string[] = [];

    if (!firstName.trim()) {
      errors.push(dictionary.errorFirstNameRequired[lang]);
    }
    if (!lastName.trim()) {
      errors.push(dictionary.errorLastNameRequired[lang]);
    }
    if (!orgType.trim()) {
      errors.push(dictionary.errorOrgTypeRequired[lang]);
    }
    if (!orgName.trim()) {
      errors.push(dictionary.errorOrgNameRequired[lang]);
    }
    // Check if the user has chosen Yes/No for 'student'
    if (student === null) {
      errors.push(dictionary.errorStudentRequired[lang]);
    }
    if (!country.trim()) {
      errors.push(dictionary.errorCountryRequired[lang]);
    }
    if (!comeFrom.trim()) {
      errors.push(dictionary.errorComeFromRequired[lang]);
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }
    try {
      const new_sett = await handleClickSubmit({
        email: email_f.trim(),
        first_name: firstName,
        last_name: lastName,
        organisation_name: orgName,
        organisation_type: orgType,
        language: lang.trim().toLowerCase(),
        country: country.toUpperCase(),
        is_student: student || false,
        come_from: comeFrom,
      });
      setUserSettings(new_sett);
      onComplete();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="absolute z-50 top-0 left-0 w-full h-full bg-white bg-opacity-80 flex items-center justify-center">
      <ScrollArea className="bg-white border rounded-2xl shadow-lg">
        <div className="text-omnilex_neutral-1000 max-w-[580px] max-h-[90vh] md:max-h-[90vh]">
          <div className="text-2xl font-normal mb-4 py-3 px-11 bg-omnilex_neutral-900 text-omnilex_neutral-100 flex flex-row gap-5 items-baseline">
            <img src="/logo/Icon_Omnilex_Light.svg" className=" h-4" />
            <div>{dictionary.letsetupOmnilex[lang]}</div>
          </div>

          <div className="px-11 flex flex-col gap-8 py-4">
            {errorMessages.length > 0 && (
              <div className="p-3 bg-red-100 text-red-700 rounded text-sm">
                <ul className="list-disc list-inside">
                  {errorMessages.map((msg, idx) => (
                    <li key={idx}>{msg}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className=" flex flex-col gap-5">
              <div className="">
                <div className="mb-1 font-medium text-sm">
                  {dictionary.emailLabel[lang]}
                </div>
                <Input
                  type="email"
                  value={email_f}
                  disabled
                  className="w-full px-3 py-2 border border-gray-300 rounded bg-gray-100 cursor-not-allowed"
                />
              </div>

              <div className=" grid grid-cols-2 gap-5">
                <div className="">
                  <div className="mb-1 font-medium text-sm">
                    {dictionary.firstNameLabel[lang]}
                  </div>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                  />
                </div>

                <div className="">
                  <div className="mb-1 font-medium text-sm">
                    {dictionary.lastNameLabel[lang]}
                  </div>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className=" flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <div className="mb-1 font-medium text-sm">
                  {dictionary.areYouStudentLabel[lang]}
                </div>
                <div className="flex gap-6">
                  <label className="flex items-center gap-2 text-base cursor-pointer">
                    <input
                      type="radio"
                      name="student"
                      value="Yes"
                      className="w-5 h-5 cursor-pointer accent-omnilex_neutral-900"
                      checked={student === true}
                      onChange={() => setStudent(true)}
                    />
                    {dictionary.yesOption[lang]}
                  </label>
                  <label className="flex items-center gap-2 text-base cursor-pointer">
                    <input
                      type="radio"
                      name="student"
                      value="No"
                      className="w-5 h-5 cursor-pointer accent-omnilex_neutral-900"
                      checked={student === false}
                      onChange={() => setStudent(false)}
                    />
                    {dictionary.noOption[lang]}
                  </label>
                </div>
              </div>

              <div className="">
                <div className="mb-1 font-medium text-sm">
                  {dictionary.organizationNameLabel[lang]}
                </div>
                <Input
                  type="text"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded"
                />
              </div>

              <div className="">
                <div className="mb-1 font-medium text-sm">
                  {dictionary.organizationTypeLabel[lang]}
                </div>
                <Select value={orgType} onValueChange={setOrgType}>
                  <SelectTrigger className="w-full rounded-full font-normal">
                    <SelectValue
                      placeholder={
                        <div className=" font-normal text-omnilex_neutral-600">
                          {dictionary.selectOnePlaceholder[lang]}
                        </div>
                      }
                    />
                  </SelectTrigger>
                  <SelectContent className="font-normal">
                    <SelectItem value="Law Firm">
                      {dictionary.lawFirmOption[lang]}
                    </SelectItem>
                    <SelectItem value="Insurance">
                      {dictionary.insuranceOption[lang]}
                    </SelectItem>
                    <SelectItem value="Legal Team">
                      {dictionary.legalTeamOption[lang]}
                    </SelectItem>
                    <SelectItem value="University">
                      {dictionary.universityOption[lang]}
                    </SelectItem>
                    <SelectItem value="Institution/Government">
                      {dictionary.institutionGovOption[lang]}
                    </SelectItem>
                    <SelectItem value="Association/Nonprofit">
                      {dictionary.associationNonprofitOption[lang]}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <hr />

            <div className=" flex flex-col gap-5">
              <div className="">
                <div className="mb-1 font-medium text-sm">
                  {dictionary.jurisdictionLabel[lang]}
                </div>

                <Select onValueChange={setCountry} defaultValue={country}>
                  <SelectTrigger className="w-full rounded-full">
                    <SelectValue
                      placeholder={
                        <div className=" font-normal text-omnilex_neutral-600">
                          {dictionary.selectOnePlaceholder[lang]}
                        </div>
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="CH">
                      <div className="flex items-center gap-2 font-normal">
                        <img src="/flags/4x3/ch.svg" className="h-3" />
                        <div>{dictionary.switzerlandOption[lang]}</div>
                      </div>
                    </SelectItem>
                    <SelectItem value="DE">
                      <div className="flex items-center gap-2 font-normal">
                        <img src="/flags/4x3/de.svg" className="h-3" />
                        <div>{dictionary.germanyOption[lang]}</div>
                      </div>
                    </SelectItem>
                    <SelectItem value="FR">
                      <div className="flex items-center gap-2 font-normal">
                        <img src="/flags/4x3/fr.svg" className="h-3" />
                        <div>{dictionary.franceOption[lang]}</div>
                      </div>
                    </SelectItem>
                    <SelectItem value="BR">
                      <div className="flex items-center gap-2 font-normal">
                        <img src="/flags/4x3/br.svg" className="h-3" />
                        <div>{dictionary.brazilOption[lang]}</div>
                      </div>
                    </SelectItem>
                    <SelectItem value="US">
                      <div className="flex items-center gap-2 font-normal">
                        <img src="/flags/4x3/us.svg" className="h-3" />
                        <div>{dictionary.usaOption[lang]}</div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {sourceParam === "" && (
                <>
                  <div className="">
                    <div className="mb-1 font-medium text-sm">
                      {dictionary.howDidYouFindUsLabel[lang]}
                    </div>
                    <Input
                      type="text"
                      value={comeFrom}
                      placeholder="e.g. Linkedin, Weblaw, Friends, .."
                      onChange={(e) => setComeFrom(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex justify-end pb-6 items-center gap-8">
              <a href="/login">
                <div className="font-medium text-omnilex_neutral-600 hover:text-omnilex_neutral-800">
                  Back to Sign in
                </div>
              </a>

              <Button
                variant="omnilex"
                onClick={async () => await handleSubmit()}
              >
                {dictionary.submitButton[lang]}
              </Button>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}

export default GetStartedOverlay;
