"use client";
import { Nav } from "@/components/nav";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { GoogleAnalytics } from "@next/third-parties/google";
import {
  canGetOmnilexDailyBanner,
  canUseAccountManager,
  canUseAnonimisation,
  canUseCoverageCheck,
  canUseFollowUpQuestion,
  canUseHistory,
  canUseQuestion,
  needRegistration,
} from "@shared/acm/utils";
import { getAllEvalDataPoints } from "@shared/db/eval/src/utils_evalDataPoints";
import {
  createUserIfNew,
  getUserByEmail,
} from "@shared/db/usersandhistory/src/utils_users_groups";
import { EvalDataPoint } from "@shared/dto/eval";
import { UserSettings } from "@shared/dto/user-settings";
import { detectIncognito } from "detectincognitojs";
import {
  Activity,
  CircleDotDashed,
  Dot,
  Globe,
  Rocket,
  Users,
} from "lucide-react";
import { signOut, useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import { FiBookOpen } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { createAnonymizedLabel, initGA, logEvent } from "./analytics";
import Banner from "./banner";
import { getLocalStorageItem, setLocalStorageItem } from "./demo";
import ErrorBoundary from "./errorBoundary";
import { DatasetStats } from "./evals/datasetStats";
import KPIChart from "./kpi";
import GetStartedOverlay from "./registrationBanner";
import {
  available_Languages,
  debugModeGlobal,
  emptyThread,
  filesInputGlobal,
  followUpQuestionInputGlobal,
  langType,
  languageSettingGlobal,
  questionInputGlobal,
  selectedThreadGlobal,
  userSettingGlobal,
} from "./state";

const LanguagePicker = () => {
  const [lang, setLang] = useRecoilState(languageSettingGlobal);
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <div className=" text-sm p-2 rounded hover:bg-white hover:text-omnilex_neutral-900 h-9 flex flex-row gap-1 items-center">
            <Globe className="h-3 w-3" />
            {lang.toUpperCase()}
          </div>
        </PopoverTrigger>
        <PopoverContent side="right" className=" w-fit mb-2">
          <div>
            {available_Languages.map((l, idx) => {
              return (
                <div
                  key={idx}
                  className={cn(
                    " cursor-pointer hover:underline rounded px-2",
                    lang === l.code ? " bg-omnilex_neutral-400" : ""
                  )}
                  onClick={(e) => {
                    setLang(l.code);
                  }}
                >
                  {l.name}
                </div>
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

const dictionary = {
  new: {
    en: "New",
    it: "Nuovo",
    fr: "Nouveau",
    de: "Neu",
    pt: "Novo",
  },
  history: {
    en: "History",
    it: "Storia",
    fr: "Historique",
    de: "Verlauf",
    pt: "Histórico",
  },
  coverageCheck: {
    en: "Coverage Check",
    it: "Verifica della copertura",
    fr: "Vérification de la couverture",
    de: "Deckungsprüfung",
    pt: "Verificação de cobertura",
  },
  anonymization: {
    en: "Anonymization",
    it: "Anonimizzazione",
    fr: "Anonymisation",
    de: "Anonymisierung",
    pt: "Anonimização",
  },
  account: {
    en: "Account",
    it: "Account",
    fr: "Compte",
    de: "Konto",
    pt: "Conta",
  },
  accountmanager: {
    en: "Account Manager",
    it: "Responsabile dell'account",
    fr: "Gestionnaire de compte",
    de: "Kontoverwalter",
    pt: "Gerente de conta",
  },
  evals: {
    en: "Evals",
    it: "Evals",
    fr: "Evals",
    de: "Evals",
    pt: "Evals",
  },
};

export function getPreferredLang(fallback: langType = "en") {
  if (typeof window !== "undefined") {
    const stored = localStorage.getItem("preferredLang");
    if (stored) {
      return JSON.parse(stored);
    }
    const browserLang =
      navigator.language || navigator.languages?.[0] || fallback;
    console.log("Browser language", browserLang);
    const shortLang = browserLang.split("-")[0];
    return available_Languages
      .map((e) => e.code)
      .includes(shortLang as langType)
      ? shortLang
      : fallback;
  }
  return fallback;
}

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <RecoilRoot>
        <ClientLayoutSub children={children} />
      </RecoilRoot>
    </>
  );
}

function ClientLayoutSub({ children }: { children: React.ReactNode }) {
  const session = useSession();
  const pathname = usePathname();
  const [lang, setLang] = useRecoilState(languageSettingGlobal);

  useEffect(() => {
    detectIncognito().then((result) => {
      if (result.isPrivate) {
        logEvent("Application", "Incognito Mode");
      }
    });
  }, []);

  const [userSettings, setUserSettings] =
    useRecoilState<UserSettings>(userSettingGlobal);

  useEffect(() => {
    if (session.data?.user?.email) {
      getUserByEmail(session.data?.user?.email).then((user) => {
        if (user) {
          setUserSettings(JSON.parse(user.settings));
          // console.log("User settings updated", user.settings);
          setLocalStorageItem("hasAlreadyLoginOnce", "yes");
        }
      });
    }
  }, [session.data?.user?.email]);

  useEffect(() => {
    const savedLang = getLocalStorageItem(
      "preferredLang",
      getPreferredLang(lang)
    );
    if (savedLang !== lang) {
      setLang(savedLang);
    }
  }, []);
  useEffect(() => {
    setLocalStorageItem("preferredLang", lang);
  }, [lang]);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [permissions, setPermissions] = useState({
    canQuestion: false,
    canHistory: false,
    canCoverageCheck: false,
    canAnonimisation: false,
    canAccountManager: false,
    canUseQuestionFollowUp: false,
    canGetOmnilexDailyBanner: false,
    needRegistration: false,
  });

  useEffect(() => {
    const email = session.data?.user?.email ?? "";

    if (!email) return;
    if (email === "") return;
    Promise.all([
      canUseQuestion(email),
      canUseHistory(email),
      canUseCoverageCheck(email),
      canUseAnonimisation(email),
      canUseAccountManager(email),
      canUseFollowUpQuestion(email),
      canGetOmnilexDailyBanner(email),
      needRegistration(email),
    ]).then(
      ([
        canQuestion,
        canHistory,
        canCoverageCheck,
        canAnonimisation,
        canAccountManager,
        canUseQuestionFollowUp,
        canGetOmnilexDailyBanner,
        needRegistration,
      ]) => {
        setPermissions({
          canQuestion,
          canHistory,
          canCoverageCheck,
          canAnonimisation,
          canAccountManager,
          canUseQuestionFollowUp,
          canGetOmnilexDailyBanner,
          needRegistration,
        });
      }
    );
  }, [session.data?.user?.email]);

  const [userRegistrationStatus, setUserRegistrationStatus] =
    useState("not-registred");

  useEffect(() => {
    const heartbeat = () => {
      fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/heartbeat`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
      })
        .then((response) => {
          if (!response.ok) {
            const error = new Error(
              `Network response was not ok: ${response.statusText}`
            );
            console.error("Error with heartbeat", error);
          } else {
            response.text().then((data) => {
              console.log("Heartbeat successful", data);
            });
          }
        })
        .catch((error) => {
          console.error("Error with heartbeat", error);
        });
    };

    initGA(
      {
        gaOptions: {
          userId: createAnonymizedLabel(session.data?.user?.email ?? ""),
          userid: createAnonymizedLabel(session.data?.user?.email ?? ""),
          user_id: createAnonymizedLabel(session.data?.user?.email ?? ""),
          org_id: session.data?.roles?.[0] ?? "defaultOrgId",
          custom_user_id: createAnonymizedLabel(
            session.data?.user?.email ?? ""
          ),
        },
      },
      session.data?.user?.email ?? "default@default.com"
    );

    getUserByEmail(session.data?.user?.email ?? "")
      .then((u) => {
        if (u) {
          if (u.status === "DEACTIVATED") {
            signOut({ redirect: true, callbackUrl: "/login" });
          }
        } else {
          createUserIfNew(session.data?.user?.email ?? "")
            .then((u) => {
              window.location.reload();
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));

    // Call heartbeat immediately on component mount
    heartbeat();
    // Call again every 20 seconds
    const intervalId = setInterval(heartbeat, 20000);
    return () => clearInterval(intervalId);
  }, [session.data?.user?.email]);

  const CustomErrorHandler: React.FC<{ error: any; errorInfo: any }> = ({
    error,
    errorInfo,
  }) => {
    // Ignore specific errors like from extensions
    if (
      error?.message.includes(
        "A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received"
      )
    ) {
      return null;
    }
    // For other errors, call App Insights and log to console
    console.error("Uncaught error:", error, errorInfo);
    return null;
  };

  const degubMode = useRecoilValue(debugModeGlobal);
  const [currentThread, setCurrentThread] =
    useRecoilState(selectedThreadGlobal);
  const [questionInput, setQuestionInput] = useRecoilState(questionInputGlobal);
  const [followUpQuestionInput, setFollowUpQuestionInput] = useRecoilState(
    followUpQuestionInputGlobal
  );
  const [files, setFiles] = useRecoilState(filesInputGlobal);

  const [evalDataset, setEvalDataset] = useState<EvalDataPoint[]>([]);
  useEffect(() => {
    if (permissions.canGetOmnilexDailyBanner) {
      (async () => {
        const data = await getAllEvalDataPoints();
        setEvalDataset(data);
      })();
    }
  }, [permissions]);

  const [finishedRegistration, setFinishedRegistration] = useState(false);

  return (
    <>
      <ErrorBoundary>
        <TooltipProvider delayDuration={0}>
          <div className="flex flex-col md:flex-row w-screen h-screen">
            {degubMode ? (
              <>
                <div className=" w-[300px] flex flex-col gap-2 p-2 h-[100vh] overflow-y-auto no-scrollbar">
                  <div>{JSON.stringify(session.data?.user?.email)}</div>

                  <div className="bg-gray-100 p-2 rounded shadow-md">
                    <pre className="text-gray-800">
                      {JSON.stringify(userSettings, null, 2)}
                    </pre>
                  </div>

                  <div className="bg-gray-100 p-2 rounded shadow-md">
                    <pre className="text-gray-800">
                      {JSON.stringify(permissions, null, 2)}
                    </pre>
                  </div>

                  <div className="bg-gray-100 p-2 rounded shadow-md">
                    <pre className="text-gray-800">
                      {JSON.stringify(currentThread.id, null, 2)}
                      {JSON.stringify(currentThread.interactions, null, 2)}
                    </pre>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="bg-omnilex_neutral-1000 border-r border-black box-border px-2 flex md:flex-col flex-wrap text-white justify-between">
              <div className=" flex md:flex-col flex-row">
                <div
                  className={cn(
                    "h-[52px] items-center justify-center mx-4 md:m-0 hidden md:flex",
                    isCollapsed ? "h-[52px]" : "px-2"
                  )}
                >
                  {isCollapsed ? (
                    <img
                      src="./../logo/Icon_Omnilex_Light.svg"
                      className=" w-8 text-omnilex_neutral-400"
                    />
                  ) : (
                    "Omnilex.ai"
                  )}
                </div>

                {permissions.canQuestion ? (
                  <Nav
                    isCollapsed={isCollapsed}
                    links={[
                      {
                        title: dictionary.new[lang],
                        label: <></>,
                        icon: <GoPlus className="h-5 w-5" />,
                        href: "/dashboard/question",
                        onClick: () => {
                          setCurrentThread(emptyThread);
                          setQuestionInput("");
                          setFiles([]);
                          setFollowUpQuestionInput("");
                        },
                      },
                    ]}
                  />
                ) : (
                  <></>
                )}

                {permissions.canHistory ? (
                  <Nav
                    isCollapsed={isCollapsed}
                    links={[
                      {
                        title: dictionary.history[lang],
                        label: <></>,
                        icon: <FiBookOpen className="h-4 w-4" />,
                        href: "/dashboard/history",
                      },
                    ]}
                  />
                ) : (
                  <></>
                )}

                {permissions.canAnonimisation ||
                (session.data?.user?.email === "demo.user@demo.ch" &&
                  pathname === "/dashboard/anonymization") ? (
                  <>
                    <Nav
                      isCollapsed={isCollapsed}
                      links={[
                        {
                          title: dictionary.anonymization[lang],
                          label: <></>,
                          icon: <CircleDotDashed className="h-4 w-4" />,
                          href: "/dashboard/anonymization",
                        },
                      ]}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="mt-auto ml-auto md:mb-6 flex md:flex-col flex-row">
                {permissions.canGetOmnilexDailyBanner ? (
                  <Banner
                    mode="daily"
                    bannerDateKey="last_banner_show_date"
                    bannerOptOutKey="banner_opt_out"
                    title={
                      <div className="grid w-full place-content-center">
                        <Dot />
                      </div>
                    }
                    content={
                      <div className="w-[60vw]">
                        <div className=" py-3 pb-6">
                          Daily Report {"<3"}, lets keep an eye on what matters.
                        </div>

                        <div className="flex flex-row gap-2 w-full">
                          <div className="w-full">
                            <div className="flex flex-row gap-2 pb-2">
                              {" "}
                              <Rocket className=" h-6 w-6" /> KPI{" "}
                            </div>
                            <KPIChart />
                          </div>

                          <div className="w-full">
                            <div className="flex flex-row gap-2 pb-2">
                              {" "}
                              <Activity className=" h-6 w-6" /> Omnilex.ai Evals
                            </div>
                            <DatasetStats evalDataset={evalDataset} />
                          </div>
                        </div>
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}

                {permissions.canAccountManager ? (
                  <Nav
                    isCollapsed={isCollapsed}
                    links={[
                      {
                        title: dictionary.accountmanager[lang],
                        label: <></>,
                        icon: <Users className="h-4 w-4" />,
                        href: "/dashboard/accountmanager",
                      },
                    ]}
                  />
                ) : (
                  <></>
                )}

                {permissions.canAccountManager ? (
                  <Nav
                    isCollapsed={isCollapsed}
                    links={[
                      {
                        title: dictionary.evals[lang],
                        label: <></>,
                        icon: <Activity className="h-4 w-4" />,
                        href: "/dashboard/evals",
                      },
                    ]}
                  />
                ) : (
                  <></>
                )}

                <div className="w-full grid place-items-center cursor-pointer">
                  <LanguagePicker />
                </div>

                <Nav
                  isCollapsed={isCollapsed}
                  links={[
                    {
                      title: dictionary.account[lang],
                      label: <></>,
                      icon: (
                        <div
                          className={cn(
                            "h-6 w-6 rounded-full border border-omnilex_neutral-500 hover:border-omnilex_neutral-900",
                            "/dashboard/profile" === pathname &&
                              "border-omnilex_neutral-900"
                          )}
                        ></div>
                      ),
                      href: "/dashboard/profile",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex-1 relative w-full h-full">
              {permissions.needRegistration &&
                userSettings.registrationStatus === "not-registered" &&
                !finishedRegistration && (
                  <GetStartedOverlay
                    email={session.data?.user?.email}
                    lang={lang}
                    onComplete={() => {
                      setFinishedRegistration(true);
                    }}
                  />
                )}

              {children}
            </div>
          </div>
        </TooltipProvider>
      </ErrorBoundary>

      <GoogleAnalytics gaId="G-D08SNHVFHB" />
    </>
  );
}
