import { Button } from "@/components/ui/button";
import { SquareArrowOutUpRightIcon } from "lucide-react";
import React from "react";

interface ErrorBoundaryProps {
  /** The React children that this ErrorBoundary wraps around */
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  /** Tracks whether an error has occurred */
  hasError: boolean;
}

const dictionary = {
    somethingwentwrong: {
      en: "An error occurred!",
      de: "Ein Fehler ist aufgetreten!",
      fr: "Une erreur s'est produite !",
      it: "Si è verificato un errore!",
      pt: "Ocorreu um erro!",
    },
    tryagain: {
      en: "Retry",
      de: "Erneut versuchen",
      fr: "Réessayer",
      it: "Riprova",
      pt: "Tentar novamente",
    },
    gobackhome: {
      en: "Return to homepage",
      de: "Zur Startseite zurückkehren",
      fr: "Retourner à l'accueil",
      it: "Torna alla pagina principale",
      pt: "Voltar para a página inicial",
    },
    errorPersist: {
        en: "If the error persists, please reach out to support@omnilex.ai",
        de: "Wenn der Fehler weiterhin besteht, wenden Sie sich bitte an support@omnilex.ai",
        fr: "Si l'erreur persiste, veuillez contacter support@omnilex.ai",
        it: "Se l'errore persiste, contattare support@omnilex.ai",
        pt: "Se o erro persistir, entre em contato com support@omnilex.ai",
    }
  };

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-6 flex flex-col gap-4 items-center">

            <img src="/logo/Omnilex_Logo_Dark.svg" className="w-32 mr-4" />
      <h2>{dictionary.somethingwentwrong["en"]}</h2>

      <a href="/">
        <Button variant="omnilex">{dictionary.gobackhome["en"]} <SquareArrowOutUpRightIcon className="h-4 w-4 ml-2"/> </Button>
      </a>

      <div>
        {dictionary.errorPersist["en"]}
      </div>
    </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
