import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getLocalStorageItem, setLocalStorageItem } from "./demo";
import { languageSettingGlobal } from "./state";

type BannerMode = "daily" | "once";

interface BannerProps {
  mode?: BannerMode;
  bannerDateKey?: string;
  bannerOptOutKey?: string;
  content?: React.ReactNode;
  title?: React.ReactNode;
}

function markAsShownToday(key: string): void {
  const today = new Date().toDateString();
  setLocalStorageItem(key, today);
}

function hasUserOptedOutToday(optOutKey: string): boolean {
  const optOutDate = getLocalStorageItem(optOutKey, null);
  if (!optOutDate) return false;

  const today = new Date().toDateString();
  return optOutDate === today;
}

function setUserOptOutForToday(optOutKey: string): void {
  const today = new Date().toDateString();
  setLocalStorageItem(optOutKey, today);
}

function hasUserOptedOutForever(optOutKey: string): boolean {
  const optOutValue = getLocalStorageItem(optOutKey, null);
  return optOutValue === "true";
}

function setUserOptOutForever(optOutKey: string): void {
  setLocalStorageItem(optOutKey, "true");
}

const dictionary = {
    dontShowAgain: {
        en: "Dont show again",
        fr: "Ne plus afficher",
        it: "Non mostrare più",
        de: "Nicht mehr anzeigen",
      pt: "Não mostrar novamente"
      }
}

const Banner: React.FC<BannerProps> = ({
  mode = "daily",
  bannerDateKey = "last_banner_show_date",
  bannerOptOutKey = "banner_opt_out",
  content = (
    <div>
      <strong>Banner:</strong> This banner is shown based on the chosen mode (
      “daily” or “once”) unless you opt out.
    </div>
  ),
  title = <>Banner</>,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (mode === "daily") {
      if (!hasUserOptedOutToday(bannerOptOutKey)) {
        setIsVisible(true);
        markAsShownToday(bannerDateKey);
      }
    } else if (mode === "once") {
      if (!hasUserOptedOutForever(bannerOptOutKey)) {
        setIsVisible(true);
      }
    }
  }, [mode, bannerDateKey, bannerOptOutKey]);

  const handleOptOut = () => {
    if (mode === "daily") {
      setUserOptOutForToday(bannerOptOutKey);
    } else if (mode === "once") {
      setUserOptOutForever(bannerOptOutKey);
    }
    setIsVisible(false);
  };

  const [lang, setLang] = useRecoilState(languageSettingGlobal);

  return (
    <Dialog open={isVisible} onOpenChange={setIsVisible}>
      <DialogTrigger>{title}</DialogTrigger>
      <DialogContent className="max-w-max max-h-max">
        <div>
            <div className=" max-h-[70vh] overflow-scroll no-scrollbar ">
            {content}
            </div>
          <Button
          className="mt-4"
          onClick={handleOptOut}>{dictionary.dontShowAgain[lang]} </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Banner;
