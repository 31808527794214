import crypto from "crypto";
import ReactGA from "react-ga4";

export const initGA = (
   options: {
      nonce?: string | undefined;
      testMode?: boolean | undefined;
      gtagUrl?: string | undefined;
      gaOptions?: any;
      gtagOptions?: any;
   },
   email: string
) => {
   ReactGA.initialize("G-D08SNHVFHB", options);
   ReactGA.set({ user_company_group: extractNameAndCompany(email).company });
   ReactGA.set({ user_property_name: extractNameAndCompany(email).name });
   ReactGA.set({ user_id: createAnonymizedLabel(email) });
   ReactGA.set({ label: hashLabel(email) });
};

export const extractNameAndCompany = (email: string | null | undefined): { name: string; company: string } => {
   if (!email) return { name: "default", company: "default" };
   const [name, company] = email.split("@");
   return { name, company: company.split(".")[0] };
};

export const hashLabel = (label: string) => {
   return crypto.createHash("sha256").update(label).digest("hex").substring(0, 8);
};

export const createAnonymizedLabel = (label: string) => {
   let hashedLabel = hashLabel(label);
   return hashedLabel;
};

export const logEvent = (category = "default_category", label = "default_label", value = 1) => {
   ReactGA.event("AILEGIS - " + label, {
      category: category ?? "default_category",
      value: value ?? 1,
      label: label ?? "default_label",
      ailegis_event_category: category ?? "default_category",
      ailegis_event_label: label ?? "default_label",
      ailegis_event_value: value ?? 1,
   });
};
